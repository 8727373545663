.icon-airplane {
  width: 1em;
  height: 1em;
  fill: #BD7070;
}
.icon-facebook {
  width: 1em;
  height: 1em;
  fill: #BD7070;
}
.icon-instagram {
  width: 1em;
  height: 1em;
  fill: #BD7070;
}
.icon-location {
  width: 1em;
  height: 1em;
  fill: #BD7070;
}
.icon-logo {
  width: 2.08em;
  height: 1em;
  fill: #BD7070;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: #BD7070;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: white;
}
.icon-smmgenerator {
  width: 2.19em;
  height: 1em;
  fill: #EABDAE;
}
.icon-subbotin {
  width: 1.92em;
  height: 1em;
  fill: #EABDAE;
}
.icon-title-monogram {
  width: 8em;
  height: 1em;
  fill: #EABDAE;
}
