
.benefits, .decor {
	&-item {
		margin-bottom: 24px;
		&-icon, &-title {
			text-align: center;
			margin-bottom: 24px; }
		&-icon {
			width: 88px;
			margin: 0 auto; }
		&-title {
			font-size: 24px;
			font-weight: bold;
			color: $red; }
		&-description {
			color: $gray; } } }

.image-squire {
	position: relative;
	padding-top: 100%;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%; } }

.video {
	padding: 0;
	&-link {
		position: relative;
		display: block;
		padding-top: 33%;
		@include r(768) {
			padding-top: 60%; }
		.icon {
			position: absolute;
			top: calc(50% - 36px);
			left: calc(50% - 36px);
			font-size: 72px;
			color: $white; } } }


.decor {
	padding-top: 96px; }

.team {
	.swiper-container {}
	&-item {
		display: flex;
		flex-wrap: wrap;
		&-photo {
			margin-bottom: 12px;
			width: 170px;
			padding-right: 10px; }
		&-about {
			flex: 1 1 150px; }
		&-name {
			font-weight: bold;
			font-size: 18px;
			color: $brown; }
		&-position {
			font-weight: bold;
			color: $red;
			font-size: 14px; }
		&-description {
			color: $gray;
			margin-top: 12px; } } }

.gallery, .team {
	.swiper-container {
		padding: 12px 15px 60px; } }

.gallery-item {
	display: block;
	border-radius: 13px;
	overflow: hidden;
	&-img {
		width: 100%; } }






.tabs {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;
	margin-bottom: 12px;
	z-index: 50;
	background: $white; }

.tabs-list {
	display: flex;
	padding: 6px 0;
	user-select: none;
	width: auto;
	overflow-Y: auto;
	margin-bottom: 0;
	&::-webkit-scrollbar {
		height: 3px; }
	&-item {
		display: inline-block;
		// padding: 0 6px
		margin: 0 6px; } }

.tab-link {
	display: block;
	padding: 9px 24px;
	border-radius: 5em;
	text-transform: uppercase;
	color: $brown;
	font-weight: bold;
	white-space: nowrap;
	@include r(468) {
		padding: 3px 9px; }
	&.active {
		background: $cream;
		color: $white; } }

.tab-block {
	display: none;
	&.active {
		display: block !important;
		animation: 2s opacity ease-out; } }

@keyframes opacity {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }
