@include font-face(Alegreya, regular, '../fonts/Alegreya-Regular', 400, normal, false );
@include font-face(BloggerSans, regular, '../fonts/Bloggersanslight', 400, normal, false );
@include font-face(BloggerSans, bold, '../fonts/Bloggersansmedium', 600, normal, false );


 :root {
	--gray: #A0A0A0;
	--red: #BD7070;
	--brown: #755252;
	--light-cream: #FFF6F6;
	--cream: #EABDAE;
	--cream-hover: #FFC3B0;
	--white: #fff;
	--gradient: linear-gradient(to top, rgba(253,243,242,0), #FDF3F2); }

$gray: var(--gray);
$red: var(--red);
$brown: var(--brown);
$light-cream: var(--light-cream);
$cream: var(--cream);
$cream-hover: var(--cream-hover);
$white: var(--white);
$gradient: var(--gradient);

$title-font: Alegreya, serif;
$default-font: BloggerSans, sans-serif;

@mixin bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1; }


::-webkit-scrollbar {
	width: 10px; }

::-webkit-scrollbar-thumb {
	background-color: $cream;
	cursor: pointer;
	border-radius: 10px; }

::-webkit-scrollbar-track {
	background: $white; }

::selection {
	background: $red;
	color: #fff;
	text-shadow: none; }

.button, .button *, .to-top * {
	&::selection {
		background: transparent; } }

body {
	background: #FFF;
	font-family: $default-font;
	font-size: 16px;
	line-height: 1.5;
	@include smooth-scroll;
	@include smooth-font; }

.out {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	.section.content {
		flex-grow: 1; } }

.gradient {
	width: 100%;
	height: 100%;
	background: $gradient; }

.icon {
	position: relative;
	top: -.1em;
	fill: currentColor; }

.img-responsive {
	display: block;
	max-width: 100%; }

h1, h2 {
	font-family: $title-font;
	font-weight: normal;
	line-height: 1.2;
	margin-bottom: .75em;
	color: $red; }

h1 {
	@include css-lock(40, 64, 600, 1200); }

h2 {
	@include css-lock(24, 40, 600, 1200); }

.title-big {
	@include css-lock(40, 64, 600, 1200); }
.title-medium {
	@include css-lock(24, 40, 600, 1200); }
.title-small {
	@include css-lock(18, 24, 600, 1200); }

.title-monogram .icon-title-monogram {
	display: block;
	margin: 12px auto;
	font-size: 22px;
	color: $cream; }


p {
	margin-bottom: 12/16*1em; }

a {
	color: $red;
	&:hover {
		text-decoration: none;
		color: $cream-hover; } }


b, strong {
	font-weight: bold; }

.text-red {
	color: $red; }

.text-gray {
	color: $gray; }

.link, .button, .link:hover, .button:hover {
	text-decoration: none; }

.link {
	border-bottom: 1px dashed;
	&:hover {
		border: none;
		opacity: .75; }
	&-gray {
		color: $gray; } }

.button {
	position: relative;
	display: inline-block;
	background: $cream;
	color: $white;
	min-width: 240px;
	max-width: 100%;
	padding: 20/18*1em 30/18*1em;
	margin: .1em 0;
	text-transform: uppercase;
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: .02em;
	line-height: 1;
	border-radius: 5em;
	transition: all .5s;
	overflow: hidden;
	&:hover {
		background: $cream-hover;
		color: $white; }
	&:active {
		background: $red;
		transition: none; }
	@include md {
		width: 100%; }
	@include sm {
		padding: 23/18*1em 24/18*1em;
		font-size: 16/18*1em;
		margin: .2em 0; }

	.button-loader {
		position: absolute;
		top: calc(50% - 1.5em);
		left: calc(50% - 1.5em);
		width: 3em;
		height: 3em;
		border: 3px solid var(--white);
		border-radius: 10em;
		opacity: 0; }

	.button-content {
		opacity: 1;
		transition: all .25s ease-out; }

	&[disabled], &.disabled {
		background: var(--gray);
		pointer-events: none;
		.button-content {
			opacity: .25; } }

	&[disabled] {
		.button-loader {
			animation: 2s pulse ease-out infinite; } }

	&:focus {}

	&:hover {
		text-decoration: none;
		color: var(--white); }

	&-icon {
		text-align: left;
		padding-right: 3em;
		@include sm {
			padding-right: 24/18*3em; } }

	.icon {
		position: absolute;
		top: 0;
		right: 1em;
		height: 100%;
		font-size: 1.4em;
		@include sm {
			right: 1em; } } }




.accordion-wrapper {
 }	// border-top: 1px solid rgba(#262A2B, .15)

.accordion-item {
	// border-bottom: 1px solid rgba(#262A2B, .15)
	margin-bottom: 12px; }

.accordion-header, .accordion-content {
	padding: 10px 20px;
	font-size: 18px;
	@include r(468) {
		padding-left: 10px; } }

.accordion-content {
	@include r(468) {
		padding: 5px; } }

.accordion-header {
	position: relative;
	cursor: pointer;
	font-weight: bold;
	padding-right: 50px;
	transition: all .25s ease-out;
	color: $brown;
	background: $light-cream;
	border-radius: 2em;
	line-height: 1.2;
	@include r(468) {
		border-radius: 12px; }


	&:before, &:after {
		content: '';
		position: absolute;
		display: block;
		top: calc(50% - 1px);
		right: 20px;
		width: 20px;
		height: 2px;
		background: var(--cream);
		transition: transform .5s cubic-bezier(1,-2,.5,1.5); }

	&:after {
		transform: rotate(90deg); }

	&.active-header, &:hover {
		// color: var(--white)
		&:before, &:after {
 } }			// background: var(--white)
	&.active-header {
		&:before, &:after {
			transform: rotate(-180deg); } } }

.accordion-content {
	display: none;
	color: var(--gray);
	transition: opacity .25s .2s ease-out;
	opacity: 0;
	img {
		display: block;
		max-width: 100%;
		border-radius: 13px;
		margin-bottom: 12px; }
	&.open-content {
		opacity: 1; } }







.swiper-container-horizontal>.swiper-scrollbar {
	left: 20px;
	right: 20px;
	width: auto;
	height: 36px;
	bottom: 12px;
	background-color: transparent;
	&:before {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		right: 0;
		top: calc(50% - 2px);
		height: 4px;
		background: $light-cream;
		border-radius: 1rem; } }

.swiper-scrollbar-drag {
	top: 0;
	height: 36px;
	background-color: transparent;
	z-index: 1;
	cursor: pointer;

	&:before, &:after {
		content: '';
		position: absolute; }

	&:after {
		width: 6px;
		height: 6px;
		background: $brown;
		left: calc(50% - 3px);
		top: calc(50% - 3px);
		border-radius: 5em; }
	&:before {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 36px;
		height: 36px;
		background: $white;
		border: 1px solid $cream;
		border-radius: 5em;
		z-index: -1;
 }		// display: none

	&:hover {

		&:after {
			display: block;
			animation: 2s drug infinite ease; } } }

@keyframes drug {
	0%, 100% {
		transform: translateX(-5px); }
	50% {
		transform: translateX(5px); } }




// Modals
.fancybox-slide {
	@include r(768) {
		padding: 10px; } }

.fancybox-is-open .fancybox-bg {
	background: $brown; }

.fancybox-slide--video .fancybox-content {
	border-radius: 24px;
	overflow: hidden; }

.fancybox-is-open .fancybox-toolbar {
	// opacity: 1 !important
 }	// visibility: visible !important

.fancybox-button {
	background-color: transparent;
	padding: 0; }


.modal {
	border-radius: 13px;
	max-width: 525px;
	padding: 24px;
	display: none; }



// FORM


.field {
	.field-wrap {
		position: relative;
		display: block;
		margin-bottom: 30px;
		z-index: 1;
		textarea {
			width: 100%;
			max-width: 100%;
			border: none;
			border-bottom: 1px solid #eee; } }

	.field-black {
		background: var(--black);
		color: var(--white); }

	.field-label, .field-input {
		background: none;
		text-align: center;
		line-height: 1;
		letter-spacing: .03em;
		font-weight: 600;
		text-transform: uppercase; }

	.field-label {
		position: absolute;
		top: calc(50% - .5em);
		left: 0;
		right: 0;
		@include css-lock(12, 14, 600, 1200);
		transition: all .25s;
		user-select: none;
		pointer-events: none;
		transform: translateY(-2em); }

	.field-input {
		width: 100%;
		max-width: 100%;
		padding: 12/18*1em;
		color: var(--black);
		border-radius: 0;
		border-bottom: 1px solid #eee;
		@include css-lock(16, 24, 600, 1200);
		&::-webkit-input-placeholder {
			color: transparent;
			transition: color .5s; }
		&:focus {
			border-color: var(--black);
			&::-webkit-input-placeholder {
				color: var(--gray); } }
		&.empty-field {
			&:focus + .field-label {
				@include css-lock(12, 14, 600, 1200);
				transform: translateY(-2em); }

			+ .field-label {
				@include css-lock(16, 24, 600, 1200);
				transform: translateY(0); } } }

	.field-danger {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		text-align: center;
		font-size: 12/18*1em; } }
