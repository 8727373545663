.section {
	position: relative;
	padding-bottom: 72px; }

.background {
	@include bg;
	overflow: hidden;
	&_petal {
		position: absolute;
		width: 100%;
		height: 110%;
		background-repeat: repeat;
		z-index: -1; }
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center 100%;
		user-select: none;
		pointer-events: none; } }


.contacts-link {
	position: relative;
	padding: 0 12px 0 40px;
	display: inline-block;
	font-weight: bold;
	line-height: 1.2;
	color: $brown;
	font-size: 18px;
	margin-bottom: 1em;
	&:hover {
		color: $red; }
	@include r(768) {
		font-size: 16px;
		padding-left: 24px; }
	span {
		font-size: 12/18*1em;
		font-weight: normal;
		display: block;
		color: $gray; }
	&-icon {
		.icon {
			color: $red;
			position: absolute;
			top: 0;
			left: 0;
			font-size: 36px;
			color: $red;
			@include r(768) {
				font-size: 24px; } } } }

.socials-link {
	font-size: 36px;
	color: $brown;
	&:hover {
		color: $red; } }


.header {
	position: relative;
	display: flex;
	align-items: center;
	padding: 120px 0 0;
	&.ishome {
		min-height: 100vh;
		padding: 96px 0;
		margin-bottom: 96px; }
	@include r(768) {
		padding: 60px 0; }

	&-logo-link, &-contacts-link {
		&:hover {
			text-decoration: none; } }

	&-top, &-bottom {
		position: absolute;
		left: 0;
		right: 0;
		padding: 24px 60px;
		display: flex;
		justify-content: space-between;
		@include r(768) {
			padding: 12px 20px; }
		@include r(425) {
			padding: 6px 12px; } }
	&-top {
		top: 0; }
	&-logo {
		flex: 1 0 auto;
		font-size: 48px;
		line-height: 1;
		@include r(425) {
			font-size: 36px;
			padding-top: 9px; }
		&-link, &-link:hover {
			color: $red; } }

	&-contacts-link, &-lang-current {
		display: inline-block;
		line-height: 1.2;
		font-size: 18px;
		font-weight: bold;
		color: $brown;
		padding: 12px;
		@include r(768) {
			font-size: 16px; }
		&:hover {
			color: $red; } }

	&-contacts {
		padding: 12px; }

	&-lang {
		text-transform: uppercase;
		&-current {
			user-select: none; }
		&-list {
			display: none;
			position: relative;
			text-align: center;
			background: $brown;
			margin: -3px 0 0;
			border-radius: 6px;
			z-index: 15; }
		&-item {
			padding: 0 6px;
			&:last-child .header-lang-link {
				border-bottom: none; } }

		&-link {
			color: $white;
			display: block;
			padding: 12px 0;
			line-height: 1;
			border-bottom: 1px solid rgba(#fff, .2);
			&.active {
				display: none; }
			&:hover {
				color: $white;
				text-decoration: none; } }
		&:hover {
			.header-lang-list {
				display: block; } } }

	&-bottom {
		bottom: 0; }
	&-soc {
		font-size: 24px;
		&-link {
			color: $red; } } }


footer {
	position: relative;
	padding: 72px 0 40px; }

.footer-logo {
	font-size: 60px; }




.footer {
	&-contacts {
		flex: 1 0 auto;
		&-link {
			text-align: right;
			position: relative;
			padding-left: 36px;
			.icon {
				position: absolute;
				top: 12px;
				left: 0;
				font-size: 36px;
				color: $red; }
			span {
				font-size: 12/18*1em;
				font-weight: normal;
				display: block;
				color: $gray; } } }
	&-dev-link {
		font-size: 36px; } }

